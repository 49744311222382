import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  
    {
      label: 'Website',
      icon: 'WebsiteIcon',
      href: 'https://www.thegamecoin.net/',
    },{
      label: 'Poocoin Chart',
      icon: 'ChartIcon',
      href: 'https://poocoin.app/tokens/0xE9d78BF51ae04c7E1263A76ED89a65537B9cA903',
    },{
      label: 'BscScan',
      icon: 'HamburgerIcon',
      href: 'https://bscscan.com/token/0xE9d78BF51ae04c7E1263A76ED89a65537B9cA903',
    },
    /*
    {
      label: 'How to Buy',
      icon: 'HowToBuyIcon',
      href: 'https://sportemongo.com/how-to-buy/',
    },
    /
  
    /
    {
      label: 'Collectibles (Soon)',
      icon: 'NftIcon',
      href: '',
    },
    */
  
    {
      label: 'Socials',
      icon: 'SocialsIcon',
      items: [
        {
          label: 'Facebook',
          icon: 'TelegramIcon',
          href: 'https://www.facebook.com/GameCoinGMEX/',
        },
        {
          label: 'Twitter',
          icon: 'TwitterIcon',
          href: 'https://twitter.com/GameCoinGMEX/',
        },
        {
          label: 'Instagram',
          icon: 'RedditIcon',
          href: 'https://www.instagram.com/gamecoingmex/',
        }
      ],
    },
  
]

export default config
