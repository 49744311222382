import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'

const Div = styled.div`
  margin:10px;
`
;

const StyledNav = styled.div`
  
  margin-bottom: 40px;
`
const Title = styled.h1`
${({ theme }) => theme.mediaQueries.lg} {
  font-size: 2em;
  text-align: center;
  color: white;
}

  font-size: 1.3em;
  text-align: center;
  color: white;

  
`;
const Title2 = styled.h1`
${({ theme }) => theme.mediaQueries.lg} {
  font-size: 1.1em;
  text-align: center;
  color: white;
}

  font-size: 0.8em;
  text-align: center;
  color: white;

  
`;

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  return (
    <StyledNav>
      <Title>
        SportPokemonEgO
      </Title>
      <Div/>
      <Title2>
        Uma ganda merda de coin
      </Title2>
    </StyledNav>
  )
}

export default Nav
