import styled from 'styled-components'

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex: 1;
width: 100%;
padding: 32px 16px;
zIndex:0;
background-repeat: no-repeat;
background-position:  center;
background-size: 90% auto; 
background: url('/images/Game-Coin-About-Page-Header-2.png');

${({ theme }) => theme.mediaQueries.xs} {
  background: url('/images/Game-Coin-About-Page-Header-2.png');
  background-repeat: no-repeat;
  background-size: auto 100% ; 
  background-position: top;
  min-height: 93.4vh;
 
}
${({ theme }) => theme.mediaQueries.md} {
  background: url('/images/Game-Coin-About-Page-Header-2.png');
  background-repeat: no-repeat;
  background-size: auto 100% ; 
  background-position: top;
  min-height: 93.4vh;
 
}


${({ theme }) => theme.mediaQueries.lg} {

  background: url('/images/Game-Coin-About-Page-Header-2.png');
  background-repeat: no-repeat;
  background-size: cover; 
 

  background-position: top;
  min-height: 93.4vh;

}
@keyframes gradient { 
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
}


`
export default Container;